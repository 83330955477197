
$content-width: 100vh;
$main: #19ccaa;
$font-style: 'Helvetica', sans-serif;
$font-color: black;
$link-color: black;
$margin: 40px;
$link-hover-color: $main;
$heading-font: 'Helvetica', sans-serif;
$heading-font-color: black;
$header: black;
$background-color: #fff962;

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
