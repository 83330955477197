body {
  color: $font-color;
  font-family: $font-style;
  font-size: 1.1rem;
  background-color: $background-color;
  line-height: 1.2;
  height: 100%;
  /*-webkit-font-smoothing: antialiased;*/
}

tr, td {
  font-size: 1.1rem;  
  line-height: 1.2;

}

code {
  background-color: lightblue;
}

h1, h2, h3 {
  margin-top: 30px;
}

a {
    color: black;
}

a:visited {
}

a:hover {
  background-color: white;
}

blockquote {
  text-align: left;
}

main {
  padding: 0 $margin;
  max-width: $content-width;
  min-height: 80%;
}

time {
  color: #898989;
}
.container {
  max-width: $content-width;
  margin: 0 auto;
}

footer {
  text-align: center;
  padding: 40px;
  clear: both;
  position: relative;
  display: block;
}

ul{
 list-style:circle;
}

ul.noPoints {
  list-style: none;
  padding: 0;
  list-style-type: none;
}

tr:hover {
  background-color: white;
}

th, td {
  padding: 10px;
  text-align: left;
}

td {
  height: 50px;
  vertical-align: top;
}

.header {
  text-align: center;
  margin: 0 auto;
}

span.hovertags {
  visibility: hidden;
  color: grey;
}

a:hover + span.hovertags {
  visibility: visible;
}

.index {
  float: left;
  min-width: 300px;
}

.indexContainer {
  margin-top: -30px;
}

::selection {
  background: rgba(255,255,255,0.99);
}
::-moz-selection {
  background: rgba(255,255,255,0.99);
}